<template lang="pug">
  .schema-preview(ref="dialog")
    h2.nio-h2.text-primary-darker Your selected attributes
    .schema
      h3.nio-h3.text-primary-darker(v-if="deliverable.length > 0") Deliverable attributes
      .schema-container(v-if="deliverable.length > 0")
        ul
          li(
            v-for="path of deliverableTree"
            :class="nestDepthClass(path)"
          ) 
            div.nest-boundary(
              :class="{'has-next-sibling': hasNextSibling(path, index, 'deliverable')}"
              v-for="index in getNestDepth(path)"
            )
              .boundary-content
            span.property-name.nio-p.text-primary-dark {{ getPropertyName(path) }}
              span.mask-spacer
            NioButton(
              v-if="allowDelete(path, 'deliverable')"
              icon-name="utility-times"
              normal-icon
              @click="deleteAttribute(path, 'deliverable')"  
            )
      h3.nio-h3.text-primary-darker(v-if="filterable.length > 0") Filterable attributes
      .schema-container(v-if="filterable.length > 0")
        ul
          li(
            v-for="path of filterableTree"
            :class="nestDepthClass(path)"
          ) 
            div.nest-boundary(
              v-for="index in getNestDepth(path)"
              :class="{'has-next-sibling': hasNextSibling(path, index, 'filterable')}"
            )
              .boundary-content
            span.property-name.nio-p.text-primary-dark {{ getPropertyName(path) }}
              span.mask-spacer
            NioButton(
              v-if="allowDelete(path, 'filterable')"
              icon-name="utility-times"
              normal-icon
              @click="deleteAttribute(path, 'filterable')"  
            )
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Close
</template>

<script>

import { areSamePaths } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

export default {
  props: {
    "filterable": { type: Array, required: false, default: [] },
    "deliverable": { type: Array, required: false, default: [] }
  },
  data: () => ({
    filterableTree: [],
    deliverableTree: []
  }),
  watch: {
    deliverable: {
      deep: true, 
      handler(val) {
        this.makeTree('deliverable', this.deliverable)
      }
    },
    filterable: {
      deep: true, 
      handler(val) {
        this.makeTree('filterable', this.filterable)
      }
    }
  },
  mounted() {
    this.makeTree('deliverable', this.deliverable),
    this.makeTree('filterable', this.filterable)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    deleteAttribute(path, selectionType) {
      this.$emit('deleteAttribute', {
        path: path,
        selectionType: selectionType
      })
    },
    makeTree(mode, paths) {
      const tree = [] 
      paths.forEach(path => {
        if (!tree.find(treePath => areSamePaths(path, treePath))) {
          const newTreePaths = this.makeNewTreePaths(path)
          newTreePaths.forEach(newTreePath => {
            if (!tree.find(currTreePath => areSamePaths(currTreePath, newTreePath))) {
              tree.push(newTreePath)
            }
          })
        }
      })
      if (mode === 'deliverable') {
        this.deliverableTree = tree
      } else {
        this.filterableTree = tree
      }
    },
    makeNewTreePaths(path) {
      const newTreePaths = []
      for (let index = 0; index < path.length; index++) {
        const subTree = path.slice(0, index + 1)
        newTreePaths.push(subTree)
      }
      return newTreePaths
    },
    getPropertyName(path) {
      const deepestProperty = path[path.length - 1]
      return deepestProperty.length ? deepestProperty : deepestProperty.displayName
    },
    getNestDepth(path) {
      return path.length - 1
    },
    nestDepthClass(path) {
      return `nest-${this.getNestDepth(path)}`
    },
    hasNextSibling(path, nest, mode) {
      const paths = mode === 'deliverable' ? this.deliverableTree : this.filterableTree
      const pathIndex = paths.findIndex(p => areSamePaths(p, path))
      const nextPaths = paths.slice(pathIndex + 1)
      const siblingPath = nextPaths.find(nextPath => {
        const slicedPath = path.slice(0, nest + 1)
        const slicedNextPath = nextPath.slice(0, nest + 1)
        if (areSamePaths(slicedPath.slice(0, nest), slicedNextPath.slice(0, nest)) && slicedPath[nest] !== slicedNextPath[nest] ) {
          return true
        }
        return false
      })
      if (siblingPath) {
        return true
      }
      return false
    },
    allowDelete(path, mode) {
      const paths = mode === 'deliverable' ? this.deliverable : this.filterable
      return paths.find(currPath => areSamePaths(currPath, path)) && !path.includes('items')
    }
	}
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.schema-preview
  padding: 2.25rem 1.5rem 2.25rem 1.5rem
  background-color: $c-canvas
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  h3
    margin-bottom: 0.5rem
  p    
    margin-bottom: 2.5rem
  .schema
    text-align: left
    width: 100%
    .schema-container
      border: 0.0625rem solid $c-primary-lighter
      background-color: $c-white
      border-radius: 0.75rem
      padding: 1rem 1.5rem
      margin-top: 1.5rem
    h3
      margin-top: 2.25rem
    ul
      padding-left: 0rem
    li
      list-style: none
      display: flex
      height: 1.625rem
      .nest-boundary
        width: 0.625rem 
        margin-right: 0.625rem
        position: relative
        .boundary-content
          position: absolute
          top: -50%
          left: 0
          height: 100%
          width: 100%
      .property-name
        position: relative
        .mask-spacer
          position: absolute
          width: 0.3125rem
          height: 0.4375rem
          background-color: white
          left: -0.25rem
          bottom: 0.375rem
          z-index: 10
      .nio-button
        width: 0.625rem
        height: 0.625rem
        border: none !important
        background-color: rgba(0,0,0,0) !important
        margin-left: 0.625rem
        margin-top: 0.125rem
        ::v-deep svg
          color: $c-primary-light
      div:last-of-type
        .boundary-content
          border-bottom: 0.0625rem solid $c-primary-dark
          border-left: 0.0625rem solid $c-primary-dark
      .has-next-sibling
        .boundary-content
          border-left: 0.0625rem solid $c-primary-dark
          top: -50%
          height: 100%
    // i'm sure there's a better way to do this with SASS, but this will work for now
    .nest-0 + .nest-1,
    .nest-1 + .nest-2,
    .nest-2 + .nest-3,
    .nest-3 + .nest-4,
    .nest-4 + .nest-5,
    .nest-5 + .nest-6,
    .nest-6 + .nest-7,
    .nest-7 + .nest-8 
      .nest-boundary:not(.has-next-sibling)
        .boundary-content
          top: -30%
          height: 80%
        .nio-button
          display: inline-block
          margin-left: -1.75rem
  .actions
    display: flex
    justify-content: center
    align-items: center
    margin-top: 1.5rem
    & > * + * 
      margin-left: 1rem  
</style>