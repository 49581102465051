<template lang="pug">
  .select-attributes
    .selection-results
      p.nio-p.text-primary-dark(v-if="(filteredFilterable && filteredFilterable.length > 0) || (filteredDeliverable && filteredDeliverable.length > 0)")
        span You've selected 
        span.nio-bold(v-if="filteredDeliverable && filteredDeliverable.length > 0") {{ `${filteredDeliverable.length} deliverable ` }} 
        span(v-if="(filteredFilterable && filteredFilterable.length > 0) && (filteredDeliverable && deliverable.length > 0)") {{ `and ` }} 
        span.nio-bold(v-if="filteredFilterable && filteredFilterable.length > 0") {{ `${filteredFilterable.length} filterable ` }}
        span attribute<span v-if="(filterable && filterable.length > 1) || (deliverable && deliverable.length > 1)">s (including child properties)</span> 
        NioButton(
          v-if="!schemaPreview"
          icon-name="utility-external-link"
          normal-tertiary-append 
          @click="schemaPreview = true"
        ) View
      p.nio-p.text-primary-dark(v-else) No filterable or deliverable attributes selected
    SchemaPreview(
      v-if="schemaPreview"
      :deliverable="deliverable"
      :filterable="filterable"
      @close="schemaPreview = false"
      @deleteAttribute="deleteAttribute($event)"
    )
    NioSlatTable(
      v-if="tableColumns && attributes"
      :items="attributes"
      :columns="tableColumns"
      :search-config="searchOptions"
      :min-chars-to-search="2"
      :initial-items-per-page="itemsPerPage"
      :searchable-props="['display_name', 'description']"
      :initialOpenItemIds="initialOpenItemIds"
      action="expand-custom"
      search-header
      pagination
      custom-slat-cell
      item-id-class
      @paginationPageChanged="pageChanged"
    )
      template(v-slot:custom-slat-cell="slotProps")
        .header-summary
          .nio-slat-image
            NioIcon(
              :name="dataTypeIconName(slotProps.item)"
              :size="24"
              color="#AEB9E8"
            )
          .nio-slat-title-subtitle
            .nio-slat-title.nio-h4.text-primary-darker.nio-bold  {{ slotProps.item.display_name }}
            .nio-slat-subtitle.nio-p.text-primary-dark(v-if="slotProps.item.description") {{ slotProps.item.description}}
      template(v-slot:item-expanded="slotProps")    
        NioSchemaProperties(
          v-if="slotProps.item.properties"
          :properties="slotProps.item.properties"
          :nest="1"
          :hide-optional-properties="false"
          :required-property-names="slotProps.item.required"
          @updatePayload="updatePayload"
        )
        .attribute-details(
          v-else 
        )
          .details-content
            .display-row.display-table
              .display-column.type
                .nio-h7.text-primary-darker Type
                .nio-p.text-primary-dark {{ getPropertyType(slotProps.item) }}
            .display-row.display-table
              .display-column.enum
                .nio-h7.text-primary-darker Supported Values
                .nio-p.text-primary-dark(v-if="slotProps.item.enum") 
                  .pills
                    NioPill(
                      v-for="value of slotProps.item.enum"
                      tag
                    ) {{ value }}
                .nio-p.text-primary-dark(v-else) Any value
      template(v-slot:custom-action="slotProps")
        NioSwitch(
          v-if="slotProps.item && slotProps.item.type !== 'binary'"
          v-model="slotProps.item.deliverable"
          :check-descendant="checkIfDescendantSelected(slotProps.item,'deliverable')"
          @click.stop=""
          @update="updateRootPayload(slotProps.item, 'deliverable', $event)"
        )     
        .nio-p-small.text-primary-dark(
          v-if="slotProps.item && slotProps.item.type !== 'binary'" 
          style="text-transform: none"
        ) Deliverable
        .solo-spacer(v-if="slotProps.item && slotProps.item.type === 'binary'")
        NioSwitch(
          v-model="slotProps.item.filterable"
          :check-descendant="checkIfDescendantSelected(slotProps.item,'filterable')"
          @click.stop=""
          @update="updateRootPayload(slotProps.item, 'filterable', $event)"
        ) 
        .nio-p-small.text-primary-dark(style="text-transform: none") Filterable
    .invalid.nio-p.text-error(v-if="invalid") At least one deliverable attribute of a type other than Object is required
</template>

<script>

import SchemaPreview from './SchemaPreview'
import { setSelectionRecursively, getReadableType, makeSelected, getAttributeFromPath, getDataTypeIconName, checkIfDescendantSelected } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

export default {
  components: { SchemaPreview },
  props: {
    "attributes": { type: Array, required: false, default: null},
    "filterable": { type: Array, required: false, default: [] },
    "deliverable": { type: Array, required: false, default: [] },
    "itemsPerPage": { type: Number, required: false, default: 20 },
    "initialOpenItemIds": { type: Array, required: false }
  },
  data: () => ({
    items: null,
    searchOptions: {
      findAllMatches: true,
      threshold: 0.45
    },
    tableColumns: [],
    schemaPreview: false
  }),
  computed: {
    invalid() {
      return this.deliverable.length < 1
    },
    filteredDeliverable() {
      return this.deliverable.filter(path => !path.includes('items'))
    },
    filteredFilterable() {
      return this.filterable.filter(path => !path.includes('items'))
    }
  },
  methods: {
    checkIfDescendantSelected: checkIfDescendantSelected,
    getPropertyType(property) {
      return getReadableType(property)
    },
    makeProperties(item) {
      return Object.keys(item)
    },
    isDeliverable(item) {
      return this.deliverable.find(deliverableItem => deliverableItem.id === item.id) !== false
    },
    isFilterable(item) {
      return this.deliverable.find(deliverableItem => deliverableItem.id === item.id) !== false
    },
    updateRootPayload(property, selectionType, value) {
      if (selectionType === 'deliverable') {
        setSelectionRecursively(property, 'filterable', value, true, property.required)
      }
      setSelectionRecursively(property, selectionType, value, true, property.required)
      this.updatePayload()
    },
    updatePayload() {
      this.$emit('payloadChanged', {
        deliverable: makeSelected(this.attributes, 'deliverable'),
        filterable: makeSelected(this.attributes, 'filterable')
      })
    },
    deleteAttribute(params) {
      const attribute = this.attributes.find(attr => attr.id === params.path[0].id)
      getAttributeFromPath(params.path, attribute)[params.selectionType] = false
      this.$emit('payloadChanged', {
        deliverable: makeSelected(this.attributes, 'deliverable'),
        filterable: makeSelected(this.attributes, 'filterable')
      })
    },
    dataTypeIconName(dataType) { 
      return getDataTypeIconName(dataType)
    },
    pageChanged() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
@import "../../../styles/mixins/display-row"

.select-attributes
  .selection-results
    height: 2.25rem
    .nio-button
      margin-left: 0.5rem
      display: inline-block
    .nio-p
      line-height: 2.25rem
  .schema-preview
    margin-bottom: 2.25rem
  .invalid
    margin-top: 1.5rem
    display: flex
  ::v-deep .v-data-table__wrapper
    overflow: hidden
  ::v-deep .header-summary // TODO refactor into mixin
    display: flex
    align-items: center
    height: 100%
    .nio-slat-image 
      margin-right: 1rem  
      width: 3rem
      height: 3rem 
      justify-content: center
      align-items: center
      display: flex
      background-color: $c-canvas
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.25rem            
    .nio-slat-title-subtitle
      display: flex
      flex-direction: column
      justify-content: center
      .nio-slat-title
        white-space: nowrap
      .nio-slat-subtitle
        white-space: nowrap
        margin-top: 0.25rem
  ::v-deep .custom-slat-cell 
    .header-summary 
      .nio-slat-title, .nio-slat-subtitle
        width: 32.5rem
        white-space: pre-wrap
  ::v-deep .action-cell
    display: flex
    justify-content: flex-end
    align-items: center
    width: unset
    height: 4.75rem
    .solo-spacer
      width: 139px
    .nio-switch
      margin-bottom: 0rem
      margin-top: 0rem
      width: 3.125rem
      margin-left: 1rem
      .v-input__slot
        margin-bottom: 0rem
      .v-messages
        display: none
    & > .nio-switch + .nio-switch
      margin-left: 0.5rem
    .nio-p-small
      margin-left: 0.25rem
  ::v-deep .select-all
    .nio-p-small
      visibility: hidden
  ::v-deep .expand-custom-action
    width: 10.5rem
    justify-content: flex-start
    svg
      font-size: 0.875rem !important
  ::v-deep tr.expanded
    background-color: $c-canvas !important
  ::v-deep .expanded-row
    border-bottom: 0.0625rem solid $c-primary-lighter
    position: relative
    padding: 0rem 0.875rem 0rem 1.5rem
    background-color: $c-canvas
    .attribute-details
      padding: 1.5rem 0rem 2rem 0rem
      display: flex
      .details-content
        display: flex
        flex-direction: column
        flex-grow: 2
    .empty
      opacity: 0.7
    .display-row
      +nio-display-row
    .enum
      margin-top: 1.5rem
   
</style>
